/**
 * Authorize user
 */
export default defineNuxtRouteMiddleware(async (to) => {
    const { isAuthorised } = useAuthorisationChecker();
    const isAuthorisedForPage = isAuthorised(to.meta);

    if (!isAuthorisedForPage) {
        return showError({
            statusCode: 404,
        });
    }

    const tokenStore = useTokenStore();
    const { isLoggedIn } = storeToRefs(tokenStore);
    const { attemptNavigateToRedirect } = tokenStore;

    if (isLoggedIn.value && to.name === "login") {
        return await attemptNavigateToRedirect();
    }
});
