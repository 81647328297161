import validate from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45refresh_45token_45global from "/home/forge/master.smake.cloud/releases/v1.3.0/middleware/01.refresh-token.global.ts";
import _02_45authentication_45global from "/home/forge/master.smake.cloud/releases/v1.3.0/middleware/02.authentication.global.ts";
import _03_45authorization_45global from "/home/forge/master.smake.cloud/releases/v1.3.0/middleware/03.authorization.global.ts";
import manifest_45route_45rule from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45refresh_45token_45global,
  _02_45authentication_45global,
  _03_45authorization_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "04-localstorage-manager": () => import("/home/forge/master.smake.cloud/releases/v1.3.0/middleware/04.localstorage-manager.ts")
}