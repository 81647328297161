/**
 * Ensures user is authenticated
 */
export default defineNuxtRouteMiddleware((to) => {
    if (isGuestPage(to)) {
        return;
    }

    const { isLoggedIn } = storeToRefs(useTokenStore());

    if (!isLoggedIn.value) {
        return navigateTo({
            name: "login",
            ...(!is404Page(to) && { query: { redirect: to.fullPath } }),
        });
    }
});
