import payload_plugin_qLmFnukI99 from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/master.smake.cloud/releases/v1.3.0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_jg3TSEXw60 from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import _01_flare_client_77FFoSnclP from "/home/forge/master.smake.cloud/releases/v1.3.0/plugins/01.flare.client.ts";
import _02_smake_ui_client_oEyTOQEJwU from "/home/forge/master.smake.cloud/releases/v1.3.0/plugins/02.smake-ui.client.ts";
import _03_i18n_client_LWRFWVA9pP from "/home/forge/master.smake.cloud/releases/v1.3.0/plugins/03.i18n.client.ts";
import ssg_detect_3fHkBxLtv0 from "/home/forge/master.smake.cloud/releases/v1.3.0/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  plugin_jg3TSEXw60,
  _01_flare_client_77FFoSnclP,
  _02_smake_ui_client_oEyTOQEJwU,
  _03_i18n_client_LWRFWVA9pP,
  ssg_detect_3fHkBxLtv0
]