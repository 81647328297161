import { verify } from "@/lib/api/verify";

export const useUserStore = defineStore("userStore", () => {
    const tokenStore = useTokenStore();
    const { token } = storeToRefs(tokenStore);
    const { setToken } = tokenStore;

    const query = useQuery({
        queryKey: ["smake-use.masterApi.app.verify"],
        queryFn: verify,
        select: (data) => data,
        retry: false,
        enabled: computed(() => !!token.value),
    });

    const { error } = query;

    watchEffect(() => {
        if (!error.value) return;

        setToken();
    });

    return query;
});
