
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as dashboardlbGzYT498aMeta } from "/home/forge/master.smake.cloud/releases/v1.3.0/pages/dashboard.vue?macro=true";
import { default as entranceHq9khkGTVaMeta } from "/home/forge/master.smake.cloud/releases/v1.3.0/pages/entrance.vue?macro=true";
import { default as indexnPOESPRAA8Meta } from "/home/forge/master.smake.cloud/releases/v1.3.0/pages/index.vue?macro=true";
import { default as loginSeAHmWvfMoMeta } from "/home/forge/master.smake.cloud/releases/v1.3.0/pages/login.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/forge/master.smake.cloud/releases/v1.3.0/pages/dashboard.vue")
  },
  {
    name: "entrance",
    path: "/entrance",
    meta: entranceHq9khkGTVaMeta || {},
    component: () => import("/home/forge/master.smake.cloud/releases/v1.3.0/pages/entrance.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/master.smake.cloud/releases/v1.3.0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginSeAHmWvfMoMeta || {},
    component: () => import("/home/forge/master.smake.cloud/releases/v1.3.0/pages/login.vue")
  }
]