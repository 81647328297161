import type { JWTPayload as BasicJWTPayload } from "jose";
import { decodeJwt } from "jose";
import { refresh as refreshCall, login as loginCall } from "~/lib/api/auth";
import axios from "axios";
import { flare } from "@flareapp/js";
import { md5 } from "~/lib/hashing";

export type JWTPayload = BasicJWTPayload & {
    prv: string;
    app_id: number;
    user_id: number;
    is_owner: boolean;
    is_admin: boolean;
    permissions: string[];
    login_url: string;
};

axios.defaults.headers.common = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
};
const oneMinute = 60 * 1000;

export const useTokenStore = defineStore("tokenStore", () => {
    const timer = ref<ReturnType<typeof setTimeout>>();
    const token = useLocalStorage<string | undefined>(
        "master-administration-tokenStore-token",
        undefined,
    );

    const refreshToken = useLocalStorage<string | undefined>(
        "master-administration-tokenStore-refreshToken",
        undefined,
    );

    const refreshTokenTtl = useLocalStorage<string | undefined>(
        "master-administration-tokenStore-refreshTokenTtl",
        undefined,
    );

    const tokenLogoutHash = useLocalStorage<string | undefined>(
        "master-administration-tokenStore-tokenLogoutHash",
        undefined,
    );

    const now = useNow({ interval: oneMinute });

    const isRefreshTokenExpired = computed<boolean>(() => {
        if (refreshTokenTtl.value === undefined) {
            return true;
        }

        return (
            new Date(refreshTokenTtl.value).getTime() - now.value.getTime() < 0
        );
    });

    const isTokenExpired = computed<boolean>(() => {
        if (payload.value?.exp === undefined) {
            return true;
        }

        return payload.value.exp * 1000 - now.value.getTime() - oneMinute < 0;
    });

    const isLoggedIn = computed<boolean>(
        () =>
            token.value !== undefined &&
            (!isTokenExpired.value || !isRefreshTokenExpired.value),
    );

    function isRedirectParameterValid(redirectPath: string) {
        const redirectPathSplit = redirectPath.split("/").filter((p) => p);

        if (redirectPathSplit.length <= 1) {
            return false;
        }

        if (
            redirectPathSplit.length >= 2 &&
            redirectPathSplit[1].toLowerCase() == "login"
        ) {
            return false;
        }

        return true;
    }

    const { locale } = storeToRefs(useLocalSettingsStore());

    const config = useRuntimeConfig();
    const baseUrl = computed(() => `${config.public.masterApiBaseUrl}`);

    watchEffect(() => {
        axios.defaults.baseURL = baseUrl.value;
    });

    const payload = computed(() => {
        if (!token.value) return undefined;

        try {
            return decodeJwt<JWTPayload>(token.value);
        } catch (e) {
            return undefined;
        }
    });

    // Sync changes to the token or refreshToken in real-time
    watch([token, refreshToken, refreshTokenTtl], ([newToken]) => {
        if (!newToken) {
            void navigateToLogin();
        }
    });

    watchEffect(() => {
        if (isTokenExpired.value && !isRefreshTokenExpired.value) {
            if (
                tokenLogoutHash.value &&
                md5(token.value ?? "") === tokenLogoutHash.value
            ) {
                void logout();
            } else {
                void refresh();
            }
        }
    });

    async function navigateToLogin() {
        const fullPath = useRoute().fullPath;

        return await navigateTo({
            name: "login",
            query: { redirect: fullPath },
        });
    }

    async function logout() {
        if (!isLoggedIn.value) {
            return;
        }

        if (token.value) {
            tokenLogoutHash.value = md5(token.value);
        }

        setToken();

        await navigateToLogin();
    }

    function startRefreshTokenTimer() {
        if (timer.value) clearTimeout(timer.value);

        if (!payload.value?.exp) {
            throw new Error('"exp" not set in JWT!');
        }

        const timeout =
            payload.value?.exp * 1000 - now.value.getTime() - oneMinute;
        timer.value = setTimeout(() => {
            refresh().catch((error) => console.log(error));
        }, timeout);
    }

    function setToken(tokenData?: {
        token?: string;
        refreshToken?: string;
        refreshTokenTtl?: string;
        locale?: string;
    }) {
        refreshToken.value = tokenData?.refreshToken;
        refreshTokenTtl.value = tokenData?.refreshTokenTtl;
        token.value = tokenData?.token;
        axios.defaults.headers.common.Authorization = `Bearer ${token.value}`;
        if (tokenData?.locale) {
            locale.value = tokenData.locale;
            axios.defaults.headers.common["Accept-Language"] = tokenData.locale;
        }

        if (token.value && payload.value?.exp) startRefreshTokenTimer();
        else if (timer.value) clearTimeout(timer.value);
    }

    function refreshIfNecessary() {
        if (!isTokenExpired.value) {
            return;
        }

        return refresh();
    }

    async function refresh() {
        if (refreshToken.value) {
            if (
                tokenLogoutHash.value &&
                md5(token.value ?? "") === tokenLogoutHash.value
            ) {
                await navigateToLogin();
                return;
            }

            try {
                const refreshResponse = await refreshCall(refreshToken.value);
                setToken({
                    token: refreshResponse.access_token,
                    refreshToken: refreshResponse.refresh_token,
                    refreshTokenTtl: refreshResponse.refresh_token_ttl,
                });
                return;
            } catch {
                await navigateToLogin();
            }
        }

        await navigateToLogin();
    }

    async function login(
        email: string,
        password: string,
        intendedRoute?: string,
    ) {
        const loginData = await loginCall({ email, password });

        if (
            loginData.access_token &&
            loginData.refresh_token &&
            loginData.refresh_token_ttl
        ) {
            setToken({
                token: loginData.access_token,
                refreshToken: loginData.refresh_token,
                refreshTokenTtl: loginData.refresh_token_ttl,
            });
            tokenLogoutHash.value = undefined;

            await attemptNavigateToRedirect(intendedRoute);
        } else {
            await flare.report(
                new Error("Login was correct but no Token was received"),
            );
        }
    }

    async function attemptNavigateToRedirect(intendedRoute?: string) {
        const route = useRoute();

        const decodedRedirect = decodeURIComponent(
            intendedRoute ?? (route.query.redirect as string) ?? "",
        );

        if (decodedRedirect) {
            const [path, queryString] = decodedRedirect.split("?");
            const query = queryString
                ? Object.fromEntries(new URLSearchParams(queryString))
                : undefined;

            if (!isRedirectParameterValid(decodedRedirect)) {
                return await navigateToDashboard();
            }

            try {
                return await navigateTo({
                    path,
                    query,
                });
            } catch (e) {
                return await navigateToDashboard();
            }
        }

        return await navigateToDashboard();
    }

    async function navigateToDashboard() {
        return await navigateTo({
            name: "dashboard",
        });
    }

    setToken({
        token: token.value,
        refreshToken: refreshToken.value,
        locale: locale.value,
        refreshTokenTtl: refreshTokenTtl.value,
    });

    return {
        token: readonly(token),
        refreshToken: readonly(refreshToken),
        payload: readonly(payload),
        isLoggedIn,
        login,
        logout,
        refresh,
        refreshIfNecessary,
        setToken,
        attemptNavigateToRedirect,
        navigateToLogin,
    };
});
